<template>
  <div class="gry-bg py-5">
    <div class="profile">
      <div class="container">
        <div class="row">
          <div class="col-xxl-4 col-xl-5 col-lg-6 col-md-8 mx-auto">
            <div class="card">
              <div class="text-center pt-4">
                <h1 class="h4 fw-600">
                  {{ $t('chuang-jian-yi-ge-zhang-hu') }} </h1>
              </div>

              <div class="px-4 py-3 py-lg-4">
                <div class="">
                  <form class="form-default"  >

                    <div class="form-group phone-form-group">
                      <input type="text" class="form-control"  v-model="form.username" :placeholder="$t('quan-ming')" name="phone" autocomplete="off"  required>
                    </div>

                    <div class="form-group phone-form-group">
                      <input type="text" class="form-control"  v-model="form.email" :placeholder="$t('dian-zi-you-jian')" name="phone" autocomplete="off" required>
                    </div>

                    <div class="form-group">
                      <input type="password" class="form-control" v-model="form.password" :placeholder="$t('mi-ma')" name="password" id="password" required>
                    </div>
                    <div class="form-group">
                      <input type="password" class="form-control" v-model="form.password2" :placeholder="$t('que-ren-mi-ma-mi-ma')" name="password" id="password" required>
                    </div>

                    <div class="form-group">
                      <input type="text" class="form-control" v-model="form.invite" :placeholder="$t('yao-qing-ma')" name="password" id="password">
                    </div>

                    <div class=" mb-3">
                      <label class="aiz-checkbox">
                        <input type="checkbox"  v-model="checked" name="remember" >
                        <span class=opacity-60>{{ $t('register-desc') }}</span>
                        <span class="aiz-square-check"></span>
                      </label>
                    </div>

                    <div class="mb-5">
                      <button type="button" class="btn btn-primary btn-block fw-600" @click="submit">{{ $t('chuang-jian-zhang-hu') }}</button>
                    </div>
                  </form>
                </div>
                <div class="text-center">
                    <p class="text-muted mb-0">{{ $t('yi-you-yi-ge-zhang-hu') }}</p>
                    <a href="javascript: void(0)" @click="toPath('login')">{{ $t('deng-lu') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { register} from '@/api/login'
export default {
  data() {
    return {
      form: {
        username: '',
        email: '',
        password: '',
        password2: '',
        invite: ''
      },
      checked: ''
    }
  },
  mounted() {
    this.form.invite = this.$route.query.code
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    back() {
      this.$router.go(-1)
    },
    submit() {
      if (!this.form.username) {
        this.$toast(this.$t('qing-shu-ru-quan-ming'))
        return
      }
      if (!this.form.email) {
        this.$toast(this.$t('qing-shu-ru-you-xiang'))
        return
      }
      if (!this.form.password) {
        this.$toast(this.$t('qing-shu-ru-mi-ma'))
        return
      }
      if (!this.form.password2) {
        this.$toast(this.$t('qing-que-ren-mi-ma'))
        return
      }
      if(this.form.password != this.form.password2) {
        this.$toast(this.$t('liang-ci-mi-ma-bu-tong'))
        return
      }
      if (!this.checked) {
        this.$toast(this.$t('qing-gou-xuan-xie-yi'))
        return
      }
      let form = new FormData()
      form.append('FullName', this.form.username)
      form.append('Email', this.form.email)
      form.append('Password', this.form.password)
      form.append('PasswordConfirm', this.form.password2)
      form.append('InvitationCode', this.form.invite)
      register(form).then(res => {
        if (res.code == 0) {
          this.$toast(this.$t('zhu-ce-cheng-gong'))
          this.$router.push({
            name: 'login'
          })
        } else {
          this.$toast.fail(res.msg)
        }
      })
    }
  }
}
</script>